import bound from 'bind-decorator';
import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const DELETE_ALL_SENTINEL = -1;

function noop(_: unknown): void { void 0; }

interface MalarkeyOptions {
  repeat?: boolean;
  deleteSpeed?: number;
  pauseDuration?: number;
  typeSpeed?: number;
}

class Malarkey {
  text = '';

  functionQueue = [];

  functionArguments = [];

  functionIndex = -1;

  isStopped = true;

  stoppedCallback = noop;

  options: MalarkeyOptions;

  callback: (text: string) => void;

  constructor(callback: (text: string) => void, options: MalarkeyOptions) {
    this.callback = callback;
    this.options = options || {};
  }

  @bound private next(): void {
    if (this.isStopped) {
      this.stoppedCallback(this.text);
      this.stoppedCallback = noop;
      return;
    }
    this.functionIndex += 1;
    if (this.functionIndex === this.functionQueue.length) {
      if (!this.options.repeat) {
        this.functionIndex = this.functionQueue.length - 1;
        this.isStopped = true;
        return;
      }
      this.functionIndex = 0;
    }
    this.functionQueue[this.functionIndex].apply(
      null,
      [this.next].concat(this.functionArguments[this.functionIndex])
    );
  }

  @bound private enqueue(callback: (...args: any[]) => unknown, args: unknown): Malarkey {
    this.functionQueue.push(callback);
    this.functionArguments.push(args);
    if (this.isStopped) {
      this.isStopped = false;
      setTimeout(this.next, 0);
    }
    return this;
  }

  @bound private _type(next: () => void, typeText: string[], typeSpeed: number): void {
    const { length } = typeText;
    let i = 0;
    const typeCharacter = (): void => {
      this.text += typeText[i++];
      this.callback(this.text);
      if (i === length) {
        next();
        return;
      }
      setTimeout(typeCharacter, typeSpeed);
    };
    setTimeout(typeCharacter, typeSpeed);
  }

  @bound private _delete(next: () => void, characterCount: number, deleteSpeed: number): void {
    const finalLength =
      characterCount === DELETE_ALL_SENTINEL ? 0 : this.text.length - characterCount;
    const deleteCharacter = (): void => {
      this.text = this.text.substring(0, this.text.length - 1);
      this.callback(this.text);
      if (this.text.length === finalLength) {
        next();
        return;
      }
      setTimeout(deleteCharacter, deleteSpeed);
    };
    setTimeout(deleteCharacter, deleteSpeed);
  }

  @bound private _clear(next: () => void): void {
    this.text = '';
    this.callback(this.text);
    next();
  }

  @bound private _call(next: any, fn: (arg0: any, arg1: string) => void): void {
    fn(next, this.text);
  }

  @bound private call(fn: any): Malarkey {
    return this.enqueue(this._call, [fn]);
  }

  @bound clear(): Malarkey {
    return this.enqueue(this._clear, null);
  }

  @bound delete(characterCount?: number, deleteOptions?: { speed: any; }): Malarkey {
    if (typeof characterCount === 'object') {
      deleteOptions = characterCount;
      characterCount = DELETE_ALL_SENTINEL;
    }
    return this.enqueue(this._delete, [
      characterCount || DELETE_ALL_SENTINEL,
      (deleteOptions ? deleteOptions.speed : this.options.deleteSpeed) || 50,
    ]);
  }

  @bound pause(pauseOptions?: { duration: any; }): Malarkey {
    return this.enqueue(setTimeout, [
      (pauseOptions ? pauseOptions.duration : this.options.pauseDuration) || 2000,
    ]);
  }

  @bound triggerResume(): Malarkey {
    if (this.isStopped) {
      this.isStopped = false;
      this.next();
    }
    return this;
  }

  @bound triggerStop(fn: (_: unknown) => void): Malarkey {
    this.isStopped = true;
    this.stoppedCallback = fn || noop;
    return this;
  }

  @bound type(text: string, typeOptions?: { speed: any; }): Malarkey {
    return this.enqueue(this._type, [
      text,
      (typeOptions ? typeOptions.speed : this.options.typeSpeed) || 50,
    ]);
  }
}

@customElement('malar-key')
export class MalarkeyElement extends LitElement {
  @property({ type: Number, attribute: 'type-speed' }) typeSpeed = 50;

  @property({ type: Number, attribute: 'delete-speed' }) deleteSpeed = 50;

  @property({ type: Number, attribute: 'pause-delay' }) pauseDuration = 2000;

  @property({ type: Boolean }) repeat = true;

  get items(): string[] {
    return [...this.querySelectorAll('[slot="item"]')].map(el => el.textContent);
  }

  typist: Malarkey;

  render(): TemplateResult {
    return html``;
  }

  constructor() {
    super();
    const { callback, deleteSpeed, pauseDuration, repeat, typeSpeed } = this;
    this.typist = new Malarkey(callback, { deleteSpeed, pauseDuration, repeat, typeSpeed });
  }

  @bound callback(text: string): void {
    this.shadowRoot.textContent = text;
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.items.forEach(value =>
      this.typist.type(value).pause().delete()
    );
  }
}
